.nav-button{

    margin-top: 10px;
    font-family: Roboto;
  background-color: transparent;
  border: 0;
    font-size: 16px;
    text-decoration:none;
  
    font-weight: normal;
  
    font-stretch: normal;
  
    font-style: normal;
  
    line-height: 1.38;
  
    letter-spacing: normal;
  
    color: #000;


}
.material{
    color:#c8a047;
    transform: rotate(180deg);
}

.services{
    font-family: Roboto;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    border: 0;
    background-color: transparent;
    color: #000;
    cursor: pointer;
}

.normal-services{
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    cursor: pointer;
}

.text{
    font-family: Roboto;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    cursor: pointer;
    text-decoration: none;   
}

.main-grid{
    position: absolute;
    background-color: white;
    transition-property: all;
    transition-duration: 1s;
    outline: none;
    overflow: scroll;
}

.bold-nav-button{

    margin-top: 10px;
    font-family: Roboto;
  background-color: transparent;
  border: 0;
    font-size: 16px;
    text-decoration:none;
  
    font-weight: bold;
  
    font-stretch: normal;
  
    font-style: normal;
  
    line-height: 1.38;
  
    letter-spacing: normal;
  
    color: #000;


}
.nav-button:hover{
    cursor: pointer;
}

.dplogo{
    font-size: 20px;
    color:black;
    font-family: PlayfairDisplay;
}

.dplogo2{
    font-size: 20px;
    color: rgb(11, 75, 59);
    font-family: PlayfairDisplay;

}

.bold-nav-button{
    margin-top: 10px;
  
    font-family: Roboto;
  background-color: transparent;
  border: 0;
  font-family: Roboto;
    margin-left: 10px;
  font-size: 16px;

  font-weight: 600;

  font-stretch: normal;

  font-style: normal;

  line-height: 1.38;

  letter-spacing: normal;

  text-align: left;

  color: #000;




}
.bold-nav-button:hover{
    cursor: pointer;
}

.bold-nav-button2{
  
    font-family: Roboto;
  background-color: transparent;
  border: 0;
  font-family: Roboto;
  font-size: 16px;

  font-weight: 600;

  font-stretch: normal;

  font-style: normal;

  line-height: 1.38;

  letter-spacing: normal;

  text-align: left;

  color: #000;




}
.bold-nav-button2:hover{
    cursor: pointer;
}

.hover-grid:hover{
    cursor: pointer;
}

.nav-button{

   
    font-family: Roboto;
  background-color: transparent;
  border: 0;
    font-size: 16px;
  
    font-weight: normal;
  
    font-stretch: normal;
  
    font-style: normal;
  
    line-height: 1.38;
  
    letter-spacing: normal;
  
    color: #000;


}
.nav-button:hover{
    cursor: pointer;
}

.bold-nav-button{

  
    font-family: Roboto;
  background-color: transparent;
  border: 0;
  font-family: Roboto;

  font-size: 16px;

  font-weight: 600;

  font-stretch: normal;

  font-style: normal;

  line-height: 1.38;

  letter-spacing: normal;

  text-align: left;

  color: #000;

}
.bold-nav-button:hover{
    cursor: pointer;
}

.hover-grid:hover{
    cursor: pointer;
}

@media (max-width:980px){
    .bold-nav-button{
        font-size: 15px;
    }
    .nav-button{
        font-size: 15px;
    }
}
