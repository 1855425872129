.idea {
  font-family: Roboto;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.DP-ACADEMY {
  font-family: PlayfairDisplay;
  font-size: 37px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #0b4b3b;
  cursor:pointer
}
.DP-ACADEMY .text-style-1 {
  color: #fff;
}

.dp-academy{
  font-family: PlayfairDisplay;
  font-size: 37px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-decoration: none;

}


.WHERE-TO-FIND-US {
  font-family: Roboto;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.location {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.location2 {
  cursor: pointer;
}

.dp-link-circle-country {
  text-decoration: none;
  display: block;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 20%;
  font-size: 17px;
  font-family: Roboto;
  background-color: transparent;
  color: #fff;
}

.dp-link-circle-country:hover,
.dp-link-circle-country:active,
.dp-link-circle-active-country {
  text-decoration: none;
  display: block;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 17px;
  font-family: Roboto;
  background-color: black;
  color: white;
}

.coming-soon {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Privacy-Policy-Terms-and-Conditions-2022-DIGITAL-PRESTIGE {

  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Privacy-Policy-Terms-and-Conditions-2022-DIGITAL-PRESTIGE:hover {
  color: white;
  cursor: pointer;
}


.time-style {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.mail-style {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  text-decoration: none;
}

.mail-style:hover{
  color: white;
}


.termss{
  font-family: Nunito;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.terms2{
  font-family: Nunito;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.logo {
  background-color: black;
  text-decoration: none;
  display: block;
  text-align: center;
  border-radius: 10px;

}
.stories{
  -webkit-text-stroke: 1px #000;
  font-family: 'Great Vibes';
  font-size: 164px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #000;
  opacity: 20%;
}


@media only screen and (max-width: 1200px) {
  .dp-link-circle-country {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    border-radius: 21px;
  }

  .dp-link-circle-country:hover,
  .dp-link-circle-country:active,
  .dp-link-circle-active-country {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
  }
}

@media (max-width: 1085px) {
  .stories{
    font-size: 150px;
  }
}

@media (max-width: 1000px) {
  .stories{
    font-size: 135px;
  }
}

@media (max-width: 650px) {

  .WHERE-TO-FIND-US {
    font-size: 16px;
  }

  .location {
    font-size: 15px;
  }

  .coming-soon {
    font-size: 16px;
  }

  .Privacy-Policy-Terms-and-Conditions-2022-DIGITAL-PRESTIGE {
    font-size: 14px;
  }

  .mail-style {
    font-size: 17px;
  }

  .time-style {
    font-size: 14px;
  }
  .stories{
    font-size: 90px;
  }

}

@media (max-width: 656px) {
  .idea{
    font-size: 23px;
  }

  .dp-academy{
    font-size: 25px;
  }
  .DP-ACADEMY{
    font-size: 25px;
  }
}