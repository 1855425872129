.main-grid {
  top: 0px;
  position: absolute;
  background-color: white;
  transition-property: all;
  transition-duration: 1s;
  left: 0;
  outline: none;
}

.Rectangle-591 {
  border: solid 4px #c08d32;
  font-size: 19px;
  font-weight: lighter;
  line-height: 1.33;
  text-align: left;
  color: #fff;
}

.design-grid{
  border: solid 4px #fff;
  position: absolute;
  top:-18px;
  left: 14px;
}

.industries-text {
  /* font-family: Roboto; */
  font-size: 29px;
  font-weight: lighter;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.overlay {
  position: absolute;
  background-color: black;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.bordered-main-grid {
  top: 0px;
  position: absolute;
  background-color: white;
  transition-property: all;
  transition-duration: 1s;
  left: 0;
  border-radius: 7px;
  border: 1px solid #C2C2C2;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}


.text {
  font-family: Roboto;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  text-decoration: none;
}

.text:hover {
  cursor: pointer;
}


.WELCOME-TO-Digitization {
  font-family: Roboto;
  font-size: 45px;
  line-height: 1;
  text-align: left;
  color: #000;
}

.WELCOME-TO-Digitization-text {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}


.WELCOME-TO-Digitization-grid {
  padding: 40px 100px 35px 31px;
  background-color: rgba(200, 160, 71, 0.2)
}

.product-development-grid {
  padding: 40px 100px 35px 31px;
  background-color: rgba(200, 160, 71, 0.2)
}

.WELCOME-TO-Digitization-grid2 {
  padding: 40px 100px 35px 31px;
  background-color: rgba(200, 160, 71, 0.2)
}

.saas-image-position {
  position: absolute;
  right: -75px;
  top: 0;
}

.saas-grid {
  padding: 10px 10px 35px 15px;
  background-color: rgba(200, 160, 71, 0.2)
}

.automation-grid {
  padding: 10px 10px 20px 15px;
  background-color: rgba(200, 160, 71, 0.2)
}

.enterprise-grid {
  padding: 20px 10px 35px 40px;
  background-color: #f6f4f4;
}

.blockChain-grid {
  padding: 20px 10px 35px 40px;
  background-color: rgba(200, 160, 71, 0.2)
}

.ai-grid {
  background-color: rgba(200, 160, 71, 0.2);
  padding: 35px 10px 15px 15px;
}

.machine-learning-grid {
  padding: 10px 15px 35px 15px;
  background-color: #f6f4f4;
}

.ai-position {
  position: absolute;
  top: 47px;
  right: -140px;
}

.advisory-grid {
  background-color: #f6f4f4;
  padding: 10px 10px 35px 30px;
}

.mobile-grid {
  padding: 10px 15px 35px 15px;
  background-color: #f6f4f4;
}

.robot-image-position {
  position: absolute;
  right: 15px;
}

.web-image-position {
  position: absolute;
  left: -45px
}

.automation-image-position {
  position: absolute;
  right: -50px;
  top: 15px;
}

.cloud-image-position {
  position: absolute;
  left: 0;
}

.blockChain-image-position {
  position: absolute;
  top: -20px;
  right: 15px;
}

.first-image-position {
  position: absolute;
  right: -300px;
  z-index: -1;
  padding-top: 40px;
}

.title {
  font-family: Roboto;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.textt {
  font-family: Roboto;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.textt2 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.technology-innovation-grid {
  padding: 29px 20px 17px 168px;
  background-color: rgba(200, 160, 71, 0.2)
}

.white-back-grid {
  padding: 29px 20px 17px 30px;
  background-color: #f6f4f4;
}

.hand-image-position {
  position: absolute;
  left: 0;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.cloud-image {
  width: 230px;
  height: 155px;
}

.web-image {
  width: 254px;
  height: 169px;
}

.saas-image {
  width: 205px;
  height: 148px;
}

.hand-image {
  width: 196px;
  height: 294px;
}

.ai-image {
  width: 242px;
  height: 161px;
}

.robot-image {
  width: 308px;
  height: 205px;
}

.blockchain-image {
  width: 200px;
  height: 161px;
}

.automation-image {
  width: 150px;
  height: 120px;
}

.first-image {
  width: 380px;
  height: 520px;
}


@media (min-width: 1570px) {
  .ai-position {
    top: 27px;
  }
}

@media (max-width: 1400px) {
  .cloud-image {
    width: 200px;
    height: 135px;
  }

  .web-image {
    width: 228px;
    height: 149px;
  }

  .saas-image {
    width: 185px;
    height: 133px;
  }

}

@media (max-width: 1050px) {
  .hand-image {
    width: 180px;
    height: 294px;
  }

  .robot-image {
    width: 285px;
    height: 190px;
  }

  .saas-image {
    width: 175px;
    height: 125px;
  }

  .cloud-image {
    width: 180px;
    height: 125px;
  }

  .blockchain-image {
    width: 175px;
    height: 140px;
  }

  .web-image {
    width: 205px;
    height: 136px;
  }

  .automation-image {
    width: 163px;
    height: 125px;
  }

  .ai-image {
    width: 220px;
    height: 141px;
  }

  .first-image {
    width: 340px;
    height: 500px;
  }

  .first-image-position {
    right: -260px;
    padding-top: 70px;
  }

}

@media (max-width: 899px) {
  .saas-grid {
    padding: 20px 5px 20px 8px;
  }

  .automation-grid {
    padding: 20px 5px 20px 8px;
  }

  .robot-image-position {
    top: 3px;
    right: unset;
    left: -20px;
  }

  .web-image {
    width: 137px;
    height: 92px;
  }

  .blockChain-grid {
    padding: 20px 5px 20px 8px;
  }

  .web-image-position {
    left: -13px;
    top: -10px
  }

  .blockchain-image {
    width: 141px;
    height: 106px;
  }

  .blockChain-image-position {
    top: -28px;
  }

  .ai-image {
    width: 165px;
    height: 116px;
  }

  .robot-image {
    width: 150px;
    height: 105px;
  }

  .ai-position {
    right: 0;
    top: 55px
  }

  .automation-image {
    width: 146px;
    height: 104px;
  }

  .automation-image-position {
    right: -20px;
  }

  .cloud-image {
    width: 177px;
    height: 118px;
  }

  .cloud-image-position {
    left: -10px;
    top: 10px;
  }

  .saas-image {
    width: 135px;
    height: 95px;
  }

  .saas-image-position {
    right: -15px;
    top: -70px;
  }

  .ai-grid {
    padding: 20px 5px 20px 8px;
  }

  .advisory-grid {
    padding: 20px 5px 20px 8px;
  }

  .product-development-grid {
    padding: 20px 5px 20px 8px;
  }

  .enterprise-grid {
    padding: 20px 5px 20px 8px;
  }

  .machine-learning-grid {
    padding: 20px 5px 20px 8px;
  }

  .robot-image {
    width: 165px;
    height: 110px;
  }

  .white-back-grid {
    padding: 20px 5px 20px 8px;
  }

  .hand-image {
    width: 135px;
    height: 203px;
  }

  .hand-image-position {
    top: -59px;
    right: -15px;
    left: unset;
  }

  .first-image {
    width: 170px;
    height: 250px;
  }

  .first-image-position {
    top: 105px;
    right: 15px
  }

  .technology-innovation-grid {
    padding: 29px 20px 17px 168px;
    background-color: rgba(200, 160, 71, 0.2)
  }

  .WELCOME-TO-Digitization-grid2 {
    padding: 20px 180px 0px 8px;
  }

  .WELCOME-TO-Digitization-grid {
    padding: 0px 8px 20px 8px;
  }

  .technology-innovation-grid {
    padding: 20px 10px 20px 8px;
  }

}

@media (max-width: 599px) {
  .textt {
    font-size: 21px;
  }

  .textt2 {
    font-size: 21px;
  }

  .technology-innovation-grid {
    padding: 20px 10px 20px 8px;
  }

  .first-image {
    width: 155px;
    height: 245px;
  }

  .first-image-position {
    top: 120px;
    right: -15px
  }

  .WELCOME-TO-Digitization {
    font-size: 28px;
  }

  .WELCOME-TO-Digitization-text {
    font-size: 21px;
  }

  .WELCOME-TO-Digitization-grid2 {
    padding: 20px 137px 0px 5px;
  }

  .WELCOME-TO-Digitization-grid {
    padding: 0px 5px 20px 5px;
  }

}

@media (max-width: 550px){
  .industries-text{
    font-size: 24px;
  }
}