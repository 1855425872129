.Do-you-have-a-project-in-mind {
    font-family: Roboto;
    font-size: 35px;
    font-weight: 900;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.Do-you-have-a-project-in-mind .text-style-1 {
    font-weight: normal;
}

.underline {
    position: absolute;
    bottom: -20px;
    border-bottom: solid 5px #c8a047;
    width: 286.5px;
    left: -55px
}

.Reach-out-to-us {
    font-family: Roboto;
    font-size: 28px;
    font-weight: 900;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.Send {
    font-family: Roboto;
    font-size: 25px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    border: 0;
    padding: 8px 45px 8px 45px;
    border: solid 2px #fff;
    background-color: transparent;
    cursor: pointer;
}

.email-us {
    font-family: Roboto;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.email {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.Call-us-at {
    font-family: Roboto;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
}

.phone-num {
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
}

.black-grid {
    background-color: black;
    position: absolute;
    z-index: -1;
    transform: rotate(3deg)
}

.top-grid {
    z-index: 1;
    background-color: #c8a047;
}

.project-box {
    padding: 6px 17px 6px 18px;
    background-color: #000;
    position: absolute;
    z-index: 11;
    bottom: -35px;
    left:-90px;
    cursor: pointer;
}

.A-project-Contact-Us {
    width: 105px;
    height: 50px;
    font-family: Roboto;
    font-size: 21px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    cursor: pointer;
}

.A-project-Contact-Us .text-style-2 {
    font-weight: bold;
    font-style: normal;
}

.message-sent{
    font-family: Roboto;
    font-size: 19px;
    color: white;
}

@media(max-width: 1240px){
    .email{
        font-size: 17px;
    }
}

@media (max-width: 1050px){
    .Call-us-at{
        font-size: 23px;
    }

    .email-us{
        font-size: 23px;  
    }

    .phone-num{
       font-size: 16px; 
    }

    .email{
        font-size: 15px;  
    }

    .underline{
        width:270px
    }
}

@media (max-width: 550px) {
    .email-us{
        font-size: 17px;
    }

    .email{
        font-size: 12px;
    }
    .Call-us-at{
        font-size: 17px;
    }

    .phone-num{
        font-size: 12px; 
     }

     .Do-you-have-a-project-in-mind{
        font-size: 24px;
     }
    
}