#introduction {
    width: 245px;
}

#text {
    font-weight: normal;
}

#introduction p,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
    line-height: 1.1;
    font-stretch: normal;
    letter-spacing: normal;
    color: #000;
}

#execution {
    width: 240px;
}

#execution p,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-stretch: normal;
    letter-spacing: normal;
    color: #000;
}

#process {
    width: 240px;
}

#process p,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-stretch: normal;
    letter-spacing: normal;
    color: #000;
}

.go-back {
    font-family: Nunito;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.94;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    cursor: pointer;
}

.story-title {
    font-family: Nunito;
    font-size: 75px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #030001;
}

.Idea {
    font-family: Nunito;
    font-size: 35px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.clamped-Idea-text {
    font-family: Nunito;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
}


.clamped-Idea-text p,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

.Idea-text {
    font-family: Nunito;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.Idea-text p,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

.Rectangle-948 {
    border: solid 1px #707070;
    background-color: #fff;
}

.img-contain {
    background-color: white;
}

.blue-design {
    position: absolute;
    z-index: -1;
    top: 2.5%
}

.attachement {
    font-family: Nunito;
    font-size: 15px;
    color: black;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.attachments {
    font-family: Nunito;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.DOWNLOAD {
    font-family: Nunito;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.or {
    font-family: Nunito;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #000;
}

.read-more {
    font-family: Nunito;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #1F96C2;
    border-radius: 17px;
    padding: 5px 12px;
    cursor: pointer;
}

.pdf-box {
    padding: 9px 9px 5px 7px;
    border-radius: 5px;
    background-color: #1F96C2;
    cursor: pointer;
}

.pdf-box2 {
    font-family: Nunito;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    background-color: #1F96C2;
}

.blue-design2 {
    position: absolute;
    z-index: 1;
    top: 20%;
    width:1000px;
}

.Rectangle-817 {
    height: 600px;
    border: solid 21px #ff461e;
}

@media (min-width:1535px){
    .blue-design2 {
        top: 47%;
    }
}


@media (max-width: 1200px) {
    .blue-design2 {
        width: 760px;
        top: 47%
    }
}

@media (max-width: 1000px) {
    .story-title {
        font-size: 60px;
    }
}

@media (max-width: 900px) {
    .blue-design2 {
        width: 740px;
        top: 47%
    }
}

@media (max-width: 750px) {
    .blue-design2 {
        width: 600px;
        top: 53%
    }
}

@media (max-width: 700px) {
    .story-title {
        font-size: 55px;
    }
}

@media (max-width: 600px) {
    .story-title {
        font-size: 40px;
    }

    .Idea-text {
        font-size: 22px;
    }

    .blue-design {
        top: 0%
    }

    .blue-design2 {
        width: 450px;
        top: 50%
    }

}

@media (max-width: 500px) {
    .story-title {
        font-size: 35px;
    }

    .Idea {
        font-size: 19px;
    }

    .Idea-text {
        font-size: 20px;
    }

    .attachments {
        font-size: 8px;
    }
}

@media (max-width: 450px){
    .blue-design2 {
        width: 360px;
        top: 60%
    }
}
