.motion {
    cursor: pointer;
    transition-property: width;
    transition-duration: 0.4s;
    position: fixed;
}

.white-bar-motion {
    position: absolute;
}

.our-stories-motion {
    cursor: pointer;
    transition-property: width;
    transition-duration: 0.5s;
}

.motion5 {
    background-color: white;
    position: absolute;
    z-index: -1;
}

.motion2 {
    transition-property: width;
    transition-duration: 0.7s;
    position: fixed;
    z-index: 0.5;
    transition-timing-function: ease-in;
}

.client-stories-motion2 {
    transition-property: width;
    transition-duration: 0.7s;
    position: fixed;
    z-index: 2;
    transition-timing-function: ease-in;
}

.motion4 {
    transition-property: width;
    transition-duration: 0.4s;
    position: fixed;
    z-index: 3;
}

.white-bars {
    transition-property: height;
    transition-duration: 0.8s;
}

.white-bars2 {
    transition-property: height;
    transition-duration: 1s;
}

.motion3 {
    z-index: 2;
    transition-property: width;
    transition-duration: 0.5s;
}

.client-transition {
    position: fixed;
    transition-property: width;
    transition-duration: 0.9s;
    transition-timing-function: ease-in;
}

.client-transition2 {
    position: fixed;
    transition-property: width;
    transition-duration: 0.9s;
    transition-timing-function: ease-in;
}

.client-transition3 {
    position: absolute;
    transition-property: width;
    transition-duration: 0.6s;
    cursor: pointer;
}

.client-transition4 {
    position: absolute;
    transition-property: width;
    transition-duration: 0.6s;
    cursor: pointer;
}

.client-transition5 {
    position: fixed;
    transition-property: width;
    transition-duration: 0.55s;
}

.client-transition6 {
    position: fixed;
    transition-property: width;
    transition-duration: 0.55s;
}

.client-transition7 {
    position: fixed;
    transition-property: width;
    transition-duration: 0.4s;
}

.client-transition8 {
    position: fixed;
    transition-property: width;
    transition-duration: 0.4s;
}

.stories {
    font-family: Roboto;
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    writing-mode: vertical-lr;
    cursor: pointer;
    transform: translate(0%, 0px) rotate(180deg);
}

.grow-circle {
    width: 40px;
    height: 40px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 50px;
    animation: grow 1.9s;
}

.shrink-circle {
    width: 40px;
    height: 40px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 50px;
    animation: shrink 1.5s;
}

.shrink-circle3 {
    width: 40px;
    height: 40px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 50px;
    animation: shrink 1.75s;
}

.shrink-circle4 {
    width: 40px;
    height: 40px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 50px;
    animation: shrink 1.9s;
}

.grow-circle2 {
    width: 40px;
    height: 40px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 50px;
    animation: grow 1.9s;
}

.shrink-circle2 {
    width: 40px;
    height: 40px;
    border: solid 1px #707070;
    background-color: #fff;
    border-radius: 50px;
    animation: shrink2 1.65s;
}



@keyframes grow {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

}

@keyframes shrink {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    100% {
        background-color: transparent;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

}
@keyframes shrink2 {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    100% {
        background-color: transparent;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -o-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
    }

}

@media (max-Width: 600px) {
    .stories {
        font-size: 22px;
    }
}

@media (max-Width: 550px) {
    .grow-circle {
        width: 24px;
        height: 24px;
    }

    .shrink-circle {
        width: 24px;
        height: 24px;
    }
}