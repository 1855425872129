.go-back {
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.94;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    cursor: pointer;
}

.story-title {
    font-family: Roboto;
    font-size: 75px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align:center;
    color: #030001;
}

.Idea {
    font-family: Roboto;
    font-size: 52px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.Idea-text{
    font-family: Roboto;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.Idea-text p, h1, h2, h3{
    margin: 0;
    padding: 0;
}

.Rectangle-948 {
    border: solid 1px #707070;
    background-color: #fff;
  }

  .img-contain{
    background-color: white;
  }

.red-design{
    position: absolute;
    z-index: -1;
    top:4.2%
}

.Rectangle-817{
    height: 600px;
    border: solid 21px #ff461e;
}

@media (max-width: 1000px){
    .story-title {
        font-size: 60px;
    }
}

@media (max-width: 700px){
    .story-title {
        font-size: 55px;
    }
}

@media (max-width: 600px){
    .story-title {
        font-size: 40px;
    }
    .Idea-text {
        font-size: 22px;
    } 
}

@media (max-width: 500px){
    .story-title {
        font-size: 35px;
    }
    .Idea {
        font-size: 19px;
    }
    .Idea-text {
        font-size: 20px;
    } 
}