.title {
    /* font-family: Roboto; */
    font-size: 64px;
    font-weight: lighter;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.overlay {
    position: absolute;
    background-color: black;
    z-index: -3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

}

.container {
    align-content: flex-end;
    padding-bottom: 110px;
    z-index: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}


@media only screen and (max-width: 900px) {
    .title {
        font-size: 47px;
    }
}

@media only screen and (max-width: 650px) {
    .title {
        font-size: 36px;
    }

    .container {
        padding-bottom: 80px;
    }
}