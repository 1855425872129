.Launch-your-next-tech-innovation {
  /* font-family: Roboto; */
  font-size: 60px;
  font-weight:lighter;
  line-height: 1.2;
  text-align: left;
  color: #fff;
  }


.txt-position{

    position: absolute;
    bottom: 25%;
    left: 12%;
    z-index: 3;
    
}

.container{
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 110px;
  align-content: flex-end;
}

.overlay{
  position: absolute;
  background-color: black;
  z-index: -3;
  top:0;
  left:0;
  width: 100%;
  height: 200px;

}

.overlay2{
  position: absolute;
  background-color: black;
  z-index: -3;
  top:0;
  left:0;
  width: 100%;
  height: 100%;

}

@media (max-width: 850px) {
  .Launch-your-next-tech-innovation{
  font-size: 51px;
  }

}
@media (max-width: 650px) {
  .Launch-your-next-tech-innovation{
  font-size: 39px;
  }

}
