.Let-us-make-it-a-reality {
  font-family: Roboto;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.WHERE-TO-FIND-US {
  font-family: Roboto;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.dp-academy {
  font-family: PlayfairDisplay;
  font-size: 37px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}


.location {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.location2 {
  cursor: pointer;
}

.dp-link-circle-country {
  text-decoration: none;
  display: block;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 20%;
  font-size: 17px;
  font-family: Roboto;
  background-color: transparent;
  color: #fff;
}

.dp-link-circle-country:hover,
.dp-link-circle-country:active,
.dp-link-circle-active-country {
  text-decoration: none;
  display: block;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 17px;
  font-family: Roboto;
  background-color: #c7a048;
  color: #000;
}

.coming-soon {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Privacy-Policy-Terms-and-Conditions-2022-DIGITAL-PRESTIGE {

  font-family: Roboto;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.terms {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.terms:hover {
  color: #c7a048;
}

.terms2 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Privacy-Policy-Terms-and-Conditions-2022-DIGITAL-PRESTIGE2 {

  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.Privacy-Policy-Terms-and-Conditions-2022-DIGITAL-PRESTIGE2:hover {
  color: #c7a048;
}


.time-style {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.mail-style {
  font-family: Roboto;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  text-decoration: none;
}

.mail-style:hover {
  color: #c7a048;
  text-decoration: none;
}

.Privacy-Policy-Terms-and-Conditions-2022-DIGITAL-PRESTIGE:hover {
  color: #c7a048;

}

.logo {
  background-color: black;
  text-decoration: none;
  display: block;
  text-align: center;

}

.logo:hover {
  background-color: #c7a048;
  border-radius: 18px;
}

.dp {
  font-family: PlayfairDisplay;
  font-size: 37px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: white;
  cursor: pointer;
}

.dp-stories {
  font-family: 'Great Vibes';
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #c08d32;
  cursor: pointer
}

@media only screen and (max-width: 1200px) {
  .dp-link-circle-country {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    border-radius: 21px;
  }

  .dp-link-circle-country:hover,
  .dp-link-circle-country:active,
  .dp-link-circle-active-country {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
  }
}

@media (max-width: 1050px) {
  .dp{
    font-size: 30px;
  }
  .dp-stories {
    font-size: 43px;
  }
  .dp-academy {
    font-size: 30px;
  }
}

@media (max-width: 900px) {
  .dp{
    font-size: 37px;
  }
  .dp-stories {
    font-size: 50px;
  }
  .dp-academy {
    font-size: 37px;
  }
}

@media (max-width: 650px) {
  .Let-us-make-it-a-reality {
    font-size: 29px;
  }

  .WHERE-TO-FIND-US {
    font-size: 18px;
  }

  .location {
    font-size: 16px;
  }

  .coming-soon {
    font-size: 16px;
  }

  .Privacy-Policy-Terms-and-Conditions-2022-DIGITAL-PRESTIGE {
    font-size: 16px;
  }

  .mail-style {
    font-size: 16px;
  }

  .time-style {
    font-size: 16px;
  }

  .dp-academy {
    font-size: 25px;
  }
  .dp {
    font-size: 25px;
  }
  .dp-stories {
    font-size: 35px;
  }

}