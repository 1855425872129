::-webkit-scrollbar {
  display: none;
}
/* 
::-webkit-scrollbar-track {
  background: transparent; 
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #c8a047; 
  border-radius: 5px;

} */


.title{
    font-family: Roboto;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.34;
  letter-spacing: normal;
  color: #000;
}

.text{
    font-family: Roboto;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  color: #000;
}