.DP-ACADEMY {
  font-family: PlayfairDisplay;
  font-size: 53px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.32;
  letter-spacing: normal;
  text-align: left;
  color: #0b4b3b;
}

.DP-ACADEMY .text-style-1 {
  color: #000;
}

.dp-title {
  font-family: PlayfairDisplay;
  font-size: 53px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.85;
  letter-spacing: normal;
  text-align: left;
  color: black;
  cursor: pointer;
}

.img-width {
  cursor: "pointer"
}

.dp {
  font-family: PlayfairDisplay;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #161615;
}

.Our-Stories {
  font-family: Nunito;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.dp-stories {
  font-family: 'Great Vibes';
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #c08d32;
  position: absolute;
  right: -5%;
  bottom: -80%;
  cursor: pointer
}

.dp2 {
  font-family: PlayfairDisplay;
  font-size: 43px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #161615;
}

.circle-menu{
  width: 30px;
  height: 30px;
  border: solid 3px #000;
  background-color: #000;
  border-radius: 50px;
  cursor: pointer;
}

.circle-menu2{
  width: 30px;
  height: 30px;
  border: solid 3px #ff461e;
  background-color: #000;
  border-radius: 50px;
  cursor: pointer;
}

.blue-circle-menu{
  width: 30px;
  height: 30px;
  border: solid 3px #1F96C2;
  background-color: #000;
  border-radius: 50px;
  cursor: pointer;
}

.circle {
  width: 40px;
  height: 40px;
  border: solid 8px #1F96C2;
  background-color: #fff;
  border-radius: 50px;
  cursor: pointer
}

.red-circle {
  width: 40px;
  height: 40px;
  border: solid 8px #ff461e;
  background-color: #fff;
  border-radius: 50px;
  cursor: pointer
}

.dp-stories2 {
  font-family: 'Great Vibes';
  font-size: 52px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #c08d32;
  position: absolute;
  right: -0.5%;
  bottom: -70%;
  cursor: pointer
}


@media (max-width: 500px) {
    .dp-stories {
    font-size: 29px;
  }

  .dp {
    font-size: 21px;
  }
  .dp-stories2 {
    font-size: 33px;
  }

  .dp2 {
    font-size: 35px;
  }
  .dp-title {
    font-size: 40px;
  }

  .DP-ACADEMY {
    font-size: 40px;
  }
}


@media (max-width: 330px) {

  .dp-stories2 {
    font-size: 28px;
  }

  .dp2 {
    font-size: 30px;
  }
}

