.dp {
  font-family: PlayfairDisplay;
  font-size: 82px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.dp-stories{
  font-family: 'Great Vibes';
  font-size: 120px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #c08d32;
  position: absolute;
  right: -6%;
  bottom: -90%;
}

.Ellipse-70{
  width: 65px;
  height: 65px;
  background-color: #c08d32;
  border-radius: 50px;
  position: absolute;
  top:10%;
  z-index: -1;
  right: 0;
  cursor:pointer
}

.stories {
  font-family: Roboto;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.rulers-text{
  font-family: Roboto;
  font-size: 27px;
  font-weight: 600;
  color: black;
  cursor: pointer;
}


.gold-design{
  position: absolute;
  z-index: -1;
  /* left:-70% */
  /* width: 100%; */
}

.gold-design2{
  position: absolute;
  z-index: -1;
  top:-25%
}

.circle-menu{
  width: 30px;
  height: 30px;
  border: solid 3px #000;
  background-color: #000;
  border-radius: 50px;
  cursor: pointer;
}

.list{
  font-family: Roboto;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.circle-menu2{
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50px;
  cursor: pointer;
}
.no-rotate{
  transition-property: all;
  transition-duration: 1s;
  position: absolute;
  top: 35%;
  left:-650px
}

.cursor{
  cursor: pointer;
}

.cursor1{
  cursor: pointer;
  transform: rotate(-10deg);
}

.cursor2{
  cursor: pointer;
  transform: rotate(-15deg);
}

.rotate-first{
  /* rotate: 10deg; */
  transform: rotate(10deg);
  transition-property: all;
  transition-duration: 1s;
  position: absolute;
  top: 35%;
  left:0px;
}

.leftt2{
  position: absolute;
  left: 0px;
  outline: none;
  border: 0;
  transition-property: all;
  transition-duration: 1s;
}

.leftt{
  position: absolute;
  left: -650px;
  border: 0;
  outline: none;
  transition-property: all;
  transition-duration: 1s;
}
.rotate-second{
  transform: rotate(15deg);
  transition-property: all;
  transition-duration: 1s;
  position: absolute;
  top: 53%;
  left:0px

}

.Read-more {
  font-family: Roboto;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.idea{
  font-family: Nunito;
  font-size: 68px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: 0.6;
  color: black;
}

.Recent-stories {
  font-family: 'Great Vibes';
  font-size: 68px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #c08d32;
  text-decoration-line: underline;
    text-decoration-thickness: 2px;
}

.underline{
  position: absolute;
  border-bottom: 2px solid #c08d32;
  width: 475px;
  bottom: 40%;
  z-index: 0;
}


@media (min-width: 1400px){

  .stories{
    font-size: 28px;
  }

  .dp-stories{
    font-size: 160px;
  }
  .dp{
    font-size: 110px;
  }

}


@media (max-width: 1050px){

  .dp-stories{
    font-size: 120px;
  }
  .dp{
    font-size: 80px;
  }
}

@media (max-width: 900px){

  .dp-stories{
    font-size: 110px;
  }
  .dp{
    font-size: 70px;
  }
}
@media (max-width: 850px){
  .idea{
    font-size: 32px;
  }
  .underline{
    width: 270px;
  }
}

@media (max-width: 830px){

  .dp-stories{
    font-size: 74px;
  }
  .dp{
    font-size: 50px;
  }
}

@media (max-width: 600px){

  .dp-stories{
    font-size: 53px;
  }
  .dp{
    font-size: 36px;
  }
  .Recent-stories {
    font-size: 38px;
  }
  .rulers-text{
    font-size: 22px;  
  }
  .underline2{
    width: 195px;
  }
  .stories{
    font-size: 15px;
  }

}

@media (max-width: 450px){

  .dp-stories{
    font-size: 42px;
  }
  .dp{
    font-size: 29px;
  }
}