.text {
    font-family: Roboto;
    font-size: 60px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.methodology {
    font-family: Roboto;
    font-size: 60px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.37;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.img-style {
    border-radius: 253px;
    width: 290px;
    height: 290px;

}

.img-text {
    align-items: flex-end;
}

@media (max-width: 700px) {
    .text {
        font-size: 33px;
    }

    .methodology {
        font-size: 33px;
    }

    .img-style2 {
        width: 60%;
    }

    .img-style {
        width: 120px;
        height: 120px;
    }
}

@media (min-width: 1450px) {
    .img-style {
        width: 310px;
        height: 310px;
    }

    .methodology {
        font-size: 70px;
    }
}