.our-phases{
    font-family: Roboto;
  font-size: 38px;
  line-height: 1.34;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.our-phases2{
    background-color: #c8a047;
  }

  .Plan {
    font-family: Roboto;
    font-size: 26px;
    /* font-weight: 600; */
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .Create-Roadmap {
    font-family: Roboto;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 2px 2.8px 3px 2.8px;
  background-color: #c8a047;
  }


  .box{
    border-radius: 17px;
    border: solid 1px #000;
    padding: 14px 12px 19px 12px;
  }

  .In-this-phase{
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

.carousel{
  width: 265px;
  background-color: white;
  height: 470px;
  z-index: 1;
  padding: 33.5px 20px 24px 19px;
  border: solid 1px #000;
  background-color: #fff;
}

.plan2{
  font-family: Roboto;
  font-size: 27px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
.name2{
  background-color: #c8a047;
  padding: 3.5px 3px 0.5px 3px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.description2{
  font-family: Roboto;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

  @media (max-width: 650px) {
    .In-this-phase{
      font-size: 15px;
    }
    .our-phases{
      font-size: 27px;
    }
  }