.Pilecubes {
    font-family: Roboto;
    font-size: 50px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .Industry-SaaS{
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .year{
    font-family: Roboto;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    border-bottom: 2px solid #000;
    padding-bottom: 4px;

  }

  .img-poistion{
    cursor: pointer;
  }

  .date{
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    text-decoration:none;
  }

  .role{
    font-family: Roboto;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .visit{
    font-family: Roboto;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    background-color: #c8a047;
    width: 158px;
    height:60px;
    border-radius: 8px;
  }

  .visit:hover{
    cursor:pointer;
  }


  @media only screen and (max-width: 620px) {
    .Pilecubes {
      font-size: 43px;
    }
    .Industry-SaaS{
      font-size: 18px;
    }
    .year{
      font-size: 21px;
      
    }
    .date{
      font-size: 19px;
    }
  }
