/* ::-webkit-tap-highlight-color{
  color: transparent
} */

.WHO-ARE-WE {
  font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    color: #000;
  }

  .NOT-ANOTHER-FULL-STACK-PRODUCT-COMPANY {
    font-family: Roboto;
    font-size: 38px;
    text-align: left;
    color: #000;
  }

  .company{
    background-color: #c8a047;
  }

  .ani-position{
    display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: flex-end;
      -ms-flex-pack: flex-end;
      justify-content: flex-end;
  }

  .WhoAreWe-text{
    font-family: Roboto;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;

  }

  @media (max-width: 650px) {
    .WhoAreWe-text{
    font-size: 21px;
    }

    .WHO-ARE-WE {
      font-size: 19px;
    }

    .NOT-ANOTHER-FULL-STACK-PRODUCT-COMPANY {
      font-size: 28px;
    }

  }