.Client-Stories {
    font-family: Roboto;
    font-size: 50px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #fff;

}

.img-grid{
    height: 250px;
    cursor: pointer;
    border: solid 1px #707070;
    background-color: #fff;
}

.img-grid2{
    height: 290px;
    cursor: pointer;
    border: solid 1px #707070;
    background-color: #fff;
}

.client-story-brief {
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
.client-story-brief p{
    padding:0;
    margin: 0;
}

.podcast-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}
.podcast-title{
    cursor:pointer;
}

.Rectangle-982 {
    height: 214px;
    width: 100%;
    cursor: pointer;
    border: solid 1px #707070;
    background-color: #fff;
}

.tech{
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.tech2{
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #ff461e;
}
.Rectangle-988 {
    padding: 1px 13px 0 10px;
    background-color: #ff461e;
  }

  .Rectangle-989 {
    padding: 1px 13px 0 10px;
    background-color: white;
  }

  .THE-TITLE-OF-THE-PODCAST {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #000;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  
  .THE-TITLE-OF-THE-PODCAST {
    cursor: pointer;
  }
.small-description{
    font-family: Roboto;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  .small-description p {
    padding: 0;
    margin: 0;
  }

  @media (min-width: 900px){

    .img-grid2{
        height: 250px;
    }
  }

  @media (min-width: 1250px){
    .img-grid{
        height: 320px;
    }

    .img-grid2{
        height: 249px;
    }
  }

  @media (min-width: 1400px){
    .Rectangle-982 {
        height: 250px;
        width: 100%;
    }
    .img-grid2{
        height: 310px;
    }
  }

  @media (min-width: 1650px){
    .Rectangle-982 {
        height: 290px;
        width: 100%;
    }
  }
  @media (min-width: 1800px){
    .img-grid{
        height: 350px;
    }
    .Rectangle-982 {
        height: 330px;
        width: 100%;
    }

    .img-grid2{
        height: 380px;
    }
  }

  @media (max-width: 900px){

    .img-grid2{
        height: 310px;
    }
  }
  @media (max-width: 800px){

    .Client-Stories{
        font-size: 40px;
    }
  }

  @media (max-width: 600px){
    .Client-Stories{
        font-size: 24px;
    }
    .client-story-brief{
        font-size: 14px;
    }
    .podcast-title {
        font-size: 19px;
    }
    .Rectangle-817{
        height: 228.8px;
    }

    .podcast-text {
        font-size: 21px;
    }
    .img-grid{
        height: 220px;
    }
    .img-grid2{
        height: 220px;
    }
    .tech{
        font-size: 15px;
    }
    .tech2{
        font-size: 15px;
    }
    .THE-TITLE-OF-THE-PODCAST{
        font-size: 19px;
    }
    .small-description{
        font-size: 14px;
      }

    .Rectangle-988 {
        padding: 2px 10.5px 2px 7.5px;
      }
      .Rectangle-982 {
        height: 250px;
        width: 100%;
    }
  }

  @media (max-width: 450px){
    .img-grid{
        height: 150px;
    }
    .img-grid2{
        height: 150px;
    }
  }

