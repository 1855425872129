.dp-title {
  font-family: PlayfairDisplay;
  font-size: 28px;
  cursor: pointer;
  color: white;
  text-decoration: none;
}

.black-dp-title {
  color: black;
  font-family: PlayfairDisplay;
  font-size: 28px;
  cursor: pointer;
  text-decoration: none;
}


.material {
  font-size: 50px;
}

.nav-button {
  border: 0;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: Roboto;
  color: white;
}

.dp-nav-button {
  border: 0;
  color: white;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: Roboto;
  text-decoration: none;
}

.trans-navbar {
  background-color: transparent;
}

.black-nav-button {
  border: 0;
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: Roboto;
  text-decoration: none;

}

.bold-nav-button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-family: Roboto;
  text-decoration: none;
  color: white;
}

.nav-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}

@media (max-width: 650px) {
  .dp-title {
    font-size: 26px;
  }

  .black-dp-title {
    font-size: 26px;
  }

  .bold-nav-button {
    font-size: 26px;

  }

}