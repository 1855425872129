.Launch-your-next-tech-innovation {
  /* font-family: Roboto; */
  font-weight: lighter;
  font-size: 80px;
  line-height: 1;
  text-align: left;
  color: #fff;
}


.txt-position {

  position: absolute;
  bottom: 10%;
  left: 12%;
}

.ani-grid {
  border-radius: 19px;
  border: solid 1px #fff;
  height: 38px;
  transition-property: width;
  transition-duration: 1.3s;
}

.founder {
  /* font-family: Roboto; */
  font-size: 25px;
  font-weight: lighter;
  line-height: 1.36;
  text-align: left;
  color: #fff;
}

.message-from-founder {
  cursor: pointer;
}

.fixed-grid {
  border-radius: 19px;
  border: solid 1px #fff;
  width: 67px;
  height: 38px;
}

.ani-grid:hover {
  height: 38px;
  transition-property: width;
  transition-duration: 1.3s;
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 127px;
  z-index: 3;
  align-content: flex-end;
}

.overlay {
  position: absolute;
  background-color: black;
  z-index: -3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

}

.founder-text {
  font-family: Roboto;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding-left: 9px;
}


@media (max-width: 850px) {
  .Launch-your-next-tech-innovation {
    font-size: 55px;
  }

}

@media (max-width: 650px) {
  .Launch-your-next-tech-innovation {
    font-size: 48px;
  }

  .container {
    padding-bottom: 20%;
  }

  .founder {
    font-size: 17px
  }

}