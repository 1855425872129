.small-description {
  font-family: Nunito;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.BEHIND-THE-SCENES-STORIES {
  font-family: Nunito;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.BEHIND-THE-SCENE {
  font-family: Nunito;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.Path-1168 {
  width: 401.5px;
  height: 0px;
  border-bottom: solid 4px #1F96C2;
}

.OUR-STORIES {
  font-family: Nunito;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.parag {
  font-family: Nunito;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.THE-TITLE-OF-THE-PODCAST {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.Rectangle-988 {
  padding: 1px 13px 0 10px;
  background-color: #1F96C2;
}

.tech {
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.Rectangle-982 {
  height: 214px;
  width: 100%;
  cursor: pointer;
  border: solid 1px #707070;
  background-color: #fff;
}

.Rectangle-1018 {
  width: 100%;
  height: 330px;
  border: solid 1px #707070;
  background-color: #fff;
  cursor: pointer;
}

.behind-scenes {
  font-family: Nunito;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 1px 12px 1px 12px;
  background-color: #1F96C2;
}

.behind-scenes-title {
  font-family: Nunito;
  font-size: 36px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.change-opacity {
  opacity: 1;
  transition-property: all;
  transition-duration: 400ms;
}

.change-opacity2 {
  opacity: 0;
  transition-property: all;
  transition-duration: 400ms;
}

.behind-scenes-text {
  margin-top: 8px;
  font-family: Nunito;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

@media (min-width: 800px) {
  .Rectangle-1018 {
    height: 350px;
  }
}

@media (min-width: 1200px) {
  .Rectangle-1018 {
    height: 375px;
  }
}

@media (min-width: 1400px) {
  .Rectangle-982 {
    height: 250px;
    width: 100%;
  }
}

@media (min-width: 1650px) {
  .Rectangle-982 {
    height: 290px;
    width: 100%;
  }
}

@media (min-width: 1800px) {

  .Rectangle-982 {
    height: 330px;
    width: 100%;
  }

}

@media (max-width: 900px) {

  .Rectangle-982 {
    height: 290px;
    width: 100%;
  }
}

@media (max-width: 800px) {

  .Rectangle-982 {
    height: 260px;
    width: 100%;
  }
}

@media (max-width: 700px) {

  .Rectangle-982 {
    height: 220px;
    width: 100%;
  }
}

@media (max-width: 600px) {

  .Rectangle-988 {
    padding: 2px 10.5px 2px 7.5px;
  }

  .Rectangle-982 {
    height: 290px;
    width: 100%;
  }

  .BEHIND-THE-SCENES-STORIES {
    font-size: 21px;

  }

  .Rectangle-1018 {
    height: 270px;
  }

}

@media (max-width: 500px) {

  .Rectangle-988 {
    padding: 2px 10.5px 2px 7.5px;
  }

  .Rectangle-982 {
    height: 255px;
    width: 100%;
  }

  .BEHIND-THE-SCENES-STORIES {
    font-size: 21px;

  }

  .Path-1168 {
    width: 300px;
  }

  .BEHIND-THE-SCENE {
    font-size: 20px;
  }

  .OUR-STORIES {
    font-size: 28px;
  }

  .parag {
    font-size: 15px;
  }

  .behind-scenes-title{
    font-size: 20px;
  }

  .behind-scenes-text{
    font-size: 14px;
  }

  .behind-scenes{
    font-size: 15px;
  }
}